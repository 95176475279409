.form label {
  width: 160px;
  margin-right: 15px;
}

.form-message {
  height: 150px;
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.field-row textarea {
  height: 150px;
  width: 350px;
}

.form input[type="file"] {
  align-self: end;
  margin-bottom: 7px;
}

.radio {
  margin-right: 10px;
}

#hiddenPhotos {
  display: none;
}

.field-row {
  margin-top: 15px !important;
}