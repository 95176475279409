@import "~bootstrap/scss/bootstrap";

body {
  background-color: rgb(247, 247, 240);
  color: rgb(73, 73, 73);
  font-size: 20px;
}

main {
  max-width: 800px;
  margin: 20px auto;
}
.container-fluid {
  padding: 0;
}

.App {
  &-header {
    background-color: green;
    border-bottom: solid 5px yellow;
    color: white;
    padding: 10px;
    text-align: center;
  }
}

.text {
  &--alt {
    color: darkgreen;
  }
}

.car {
  background-color: #555;
  box-shadow: 0 2px 3px black;

  &-container {
    max-width: 800px;
    margin: 0 auto;
  }
}

.carousel-caption {
  text-shadow: 0px 2px 4px black;
}

.btn {
  &-primary {
    background-color: green;
    border: solid 1px darkgreen;
    font-size: 25px;
  }

  &-view-messages {
    font-size: 15px;
    margin-left: 30px;
  }
}

.thank-you {
  max-width: 70%;
  margin: 30px auto;
}

// WR loader
.wr-loader {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 5000;

	&__bg {
		background-color: #000000;
		height: 100%;
		left: 0;
		opacity: .5;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__spinner {
		border: 16px solid #f3f3f3;
		border-radius: 50%;
		border-top: 16px solid #3498db;
		width: 100px;
		height: 100px;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
		z-index: 5002;
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.view-message {
  padding: 30px;
  border-bottom: solid 1px green;

  &__photo {
    text-align: center;

    img {
      max-width: 200px;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 20px;
    }
  }

  &__message-title {
    text-align: center;
    font-weight: bold;
    font-size: 25px;
  }
}